// Core Module
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_INITIALIZER, NgModule } from '@angular/core';

// Main Component
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';

// Main
import {
    LoginComponent, BillingSalesTableComponent, BillingSalesManagementComponent
} from './modules/main';



// DEVBLOCK SERVICES
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

// Service
import {
    ConfigurationService, LicenseService, ChannelService, ProductService, AuthService, CoreService, AccountService
    , SupportService, UserService, InvoiceService, MainDashboardService,
    TokenService, BillingSalesService, CoreService2, IdentityRoleService, InternationalizationService, ExcelService, ReportService, CommonService
} from './services';

import { DdlUserControlComponent } from './modules/controls/ddl-user-control/ddl-user-control.component';
import { AuthGuard } from './auth/auth-guard';
import { JwtInterceptor } from './auth/jwt-interceptor';
import { ErrorInterceptor } from './auth/error-interceptor';


// DropZone JS
import { DropzoneModule, DROPZONE_CONFIG, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { ToastrModule } from 'ngx-toastr';

// Ngx Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JwtModule, JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MarketingService } from './services/marketing.service';
import { MarketingSliderComponent } from './modules/main/marketing/marketing-slider/marketing-slider.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { BrandingService } from './services/branding.service';
import { RetailRedeemComponent } from './modules/main/retail-redeem/retail-redeem.component';
import { RetailService } from './services/retail.service';
import { SharedModule } from './modules/shared/shared.module';

import { msalConfig, loginRequest, protectedResources, protectedResources2 } from './config';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalBroadcastService, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration, MsalRedirectComponent, MsalInterceptor, MsalInterceptorConfiguration, MSAL_INTERCEPTOR_CONFIG, MsalModule } from '@azure/msal-angular';
import { DragulaModule } from 'ng2-dragula';
import { build_Configurations } from '../environments/environment.dev';
import { CurrencyPipe } from '@angular/common';
import { LogoutComponent } from './modules/main/security/logout/logout.component';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { RegionBannerComponent } from './region-banner/region-banner.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
    // Change this to your upload POST address:
    maxFilesize: 10,
    acceptedFiles: 'image/*'
};


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function jwtOptionsFactory(tokenService) {
    return {
        tokenGetter: () => tokenService.getCurrentToken()
    };
}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest
    };
}


export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();

    protectedResourceMap.set(protectedResources.estore.endpoint, protectedResources.estore.scopes);
    protectedResourceMap.set(protectedResources2.estore.endpoint, protectedResources2.estore.scopes);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function initializeApp1(common: CommonService) {
      
    return (): Promise<any> => { 
        return common.init();
      }
  }

@NgModule({
    declarations: [
        // WORKING ELEMENTS
        DdlUserControlComponent,
        LoginComponent,
        BillingSalesTableComponent,
        BillingSalesManagementComponent,
        // THEME ORIGINAL
        AppComponent,
        HeaderComponent,
        SidebarComponent,
        MarketingSliderComponent,
        PageNotFoundComponent,
        ErrorPageComponent,
        RetailRedeemComponent,
        LogoutComponent,
        RegionBannerComponent
    ],
    imports: [
        //MSAL
        MsalModule,
        // Material
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        // TagInputModule,
        MonacoEditorModule.forRoot(),
        DropzoneModule,
        // DEFAULT
        SharedModule,
        AppRoutingModule,
        NgbModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [TokenService]
            }
        }),
        DragulaModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot()
    ],
    providers:
        [
            Title,
            NgbActiveModal,
            MainDashboardService,
            CoreService,
            CoreService2,
            AccountService,
            SupportService,
            UserService,
            InvoiceService,
            ConfigurationService,
            LicenseService,
            ChannelService,
            ProductService,
            TokenService,
            UserService,
            BillingSalesService,
            InternationalizationService,
            ExcelService,
            JwtHelperService,
            IdentityRoleService,
            MarketingService,
            RetailService,
            ReportService,
            {
                provide: DROPZONE_CONFIG,
                useValue: DEFAULT_DROPZONE_CONFIG
            },
            // Auth
            AuthGuard,
            AuthService,
            { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
            { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
            BrandingService,
            { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
            {
                provide: MSAL_INSTANCE,
                useFactory: MSALInstanceFactory
            },
            {
                provide: MSAL_GUARD_CONFIG,
                useFactory: MSALGuardConfigFactory
            },
            {
                provide: MSAL_INTERCEPTOR_CONFIG,
                useFactory: MSALInterceptorConfigFactory
            },
            MsalService,
            MsalGuard,
            MsalBroadcastService,
            CurrencyPipe,
            { provide: APP_INITIALIZER,useFactory: initializeApp1, deps: [CommonService], multi: true}
        ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})

export class AppModule {
    constructor(private router: Router, private titleService: Title, private route: ActivatedRoute) {
        this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                const sub = (this.route.snapshot.firstChild ? this.route.snapshot.firstChild.data['title'] : '');
                const title = build_Configurations.Name + ' - ADMIN ' + (sub ? ' | ' + sub : '') + `(${build_Configurations.ENV})`;
                this.titleService.setTitle(title);
            }
        });
    }
}
