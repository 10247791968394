import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { BannerDefault, TopBannerModel } from '../../../models/marketing/topbanner.model';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { MarketingService } from '../../../services/marketing.service';
import { ToastrService } from 'ngx-toastr';
import { AccountService, InternationalizationService, ConfigurationService, CommonService } from '../../../services';
import { AccountRegionModel } from '../../../models/account.model';
import { ListItemModel } from '../../../models/list-item.model';
import { ProductVersionModel } from '../../../models/product/product-version.model';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-topbanner-table-modal',
    templateUrl: './top-banner-table-modal.component.html',
    styleUrls: ['./top-banner-table-modal.component.scss']
})
export class TopBannerTableModalComponent implements OnInit {
    @Input() reference: ModalTransferModel;
    regions: Array<AccountRegionModel> = [];
    language: Array<ListItemModel> = [];
    model: TopBannerModel;
    isNew: boolean;
    title: string;
    dropzoneConfig: DropzoneConfigInterface;
    readonly imagePath: string = 'https://dbcvalhallaimage.blob.core.windows.net/dbcvalhallaimage/';

    directionList = [{ name: 'Left', value: 1 }, { name: 'Right', value: 2 }];
    disableVersionTextbox: boolean;
    showDeleteImage = true;

    @Input() set open(open: boolean) {
        this._open = open;
    }
    get open(): boolean {
        return this._open;
    }
    private _open: boolean;

    @Output() modalClose = new EventEmitter();

    openVersionDetailModal: boolean;

    loading: boolean;

    @ViewChild('bannerForm') bannerForm: NgForm;

    tenant: string;
    tenantList: Array<{ name: string, guid: string }> = [];
    isCopy: boolean;

    constructor(
        private svc: MarketingService,
        private toastr: ToastrService,
        private i18Svc: InternationalizationService,
        private accountSrv: AccountService,
        private confSrv: ConfigurationService,
        public common: CommonService
        ) {
        this.i18Svc.initialize();
    }

    ngOnInit() {
        this.initMode();
        if(!this.isCopy) {
            this.getRegions();
        }
        this.getLanguage();
        this.dropzoneConfig = {
            url: this.svc.uploadImageUrl(),
            maxFilesize: 10,
            acceptedFiles: 'image/*'
        };
    }

    initTenantList() {
        this.tenantList = JSON.parse(JSON.stringify(this.common.tenantList || [])) || [];
        const i = this.tenantList.findIndex(x => x.guid === this.common.tenantId);
        if(i > -1) {
            this.tenantList.splice(i, 1);
        }
    }

    onChangeTenant() {
        if(this.tenant) {
            this.model.regionId = '' as any;
            this.removeVersion();
            this.getRegions();
        }
    }

    getRegions() {
        this.accountSrv.getAllRegions(this.tenant)
            .subscribe(data => this.onRegionGet(data), err => this.onRegioGetError(err));
    }

    onRegionGet(data) {
        if (data.result) {
            this.regions = [];
            data.result.forEach(x => this.regions.push(AccountRegionModel.parse(x)));
        } else {
            this.onRegioGetError(data.errorMessage);
        }
    }

    onRegioGetError(data) {
        this.toastr.error('Error getting regions');
    }

    getLanguage() {
        this.confSrv.getLanguageOptions()
            .subscribe(data => this.onLanguageGet(data), err => this.onLanguageGetError(err));
    }

    onLanguageGet(data) {
        if (data.result) {
            this.language = [];
            data.result.forEach(x => this.language.push(ListItemModel.Parse(x)));
        } else {
            this.onLanguageGetError(data.errorMessage);
        }
    }

    onLanguageGetError(data) {
        this.toastr.error('Error getting languages');
    }

    initMode() {
        // Set Mode
        this.isNew = this.reference.action === 'new';
        this.isCopy = this.reference.action === 'Copy';
        this.tenant = '';
        // Set Model
        if (!this.isNew) {
            this.model = this.reference.object;
            if(!this.model?.versionId) {
                this.model.versionIdString = "";
            }
            if(this.isCopy) {
                this.initTenantList();
                this.model.id = 0;
                this.removeVersion();
                this.regions = [];
                this.tenant = '';
            }
        } else {
            this.model = new TopBannerModel(0, 0, 0, '', '', '', null, '', '', 0, null, null, true, BannerDefault.color,
                BannerDefault.color, BannerDefault.color, BannerDefault.color,'', BannerDefault.directionConfig);
        }

        // Set Title
        if (this.isNew) {
            this.title = 'Create Slider';
        } else if(this.isCopy) {
            this.title = 'Copy Slider';
        } else {
            this.title = 'Slider ' + this.model.id;
        }
    }

    onBackgroundImageUploadSuccess(data) {
        this.model.backgroundUrl = this.imagePath + data[1]['result'];
    }

    onProductImageUploadSuccess(data) {
        this.model.productUrl = this.imagePath + data[1]['result'];
    }

    onVersionImageUploadError(data) { }

    get isValid() {
        return this.model.backgroundUrl && this.bannerForm?.valid;
    }
    deleteProductImage() {
        if (this.model) {
            this.model.productUrl = "";
        }
    }
    save() {
        this.saveAction(false);
    }
    saveAction(close: boolean): void {
        if (this.isNew || this.isCopy) {
            this.createSlider(close);
        } else {
            this.updateSlider(close);
        }
    }
    saveAndClose() {
        this.saveAction(true);
    }

    createSlider(close: boolean): void {
        if (this.isValid) {
            const entry = this.parseEntry(this.model);
            this.loading = true;
            this.svc.createMarketingSlider(entry, this.tenant).subscribe(data => {
                this.loading = false;
                if (data.result) {
                    this.showSuccess('Successfully Created.');
                    if (close) {
                        this.close();
                    }

                } else {
                    this.showError(data.errorMessage);
                }
            }, () => this.loading = false);
        }
    }

    updateSlider(close: boolean): void {
        if(this.bannerForm?.valid) {
        const entry = this.parseEntry(this.model);
        this.loading = true;
        this.svc.updateMarketingSlider(entry).subscribe(data => {
            this.loading = false;
            if (data.result) {
                this.showSuccess('Successfully Updated.');
                if (close) {
                    this.close();
                }
            } else {
                this.showError(data.errorMessage);
            }
        }, () => this.loading = false);
    }
    }

    parseEntry(model: TopBannerModel): TopBannerModel {
        const entry = JSON.parse(JSON.stringify(model));
        entry.headerColor = this.parseColor(entry.headerColor);
        entry.subheaderColor = this.parseColor(entry.subheaderColor);
        entry.buyBtnColor = this.parseColor(entry.buyBtnColor);
        entry.redirectBtnColor = this.parseColor(entry.redirectBtnColor);
        return entry;
    }

    parseColor(color: string): string {
        return color.replace('#', '');
    }

    onPreview(): void {
        const { productUrl, backgroundUrl, header, subheader, buyBtnColor, headerColor, redirectBtnColor, subheaderColor, directionConfig, redirectUrl } = this.model;
        let url = 'marketing-slider?productUrl=' + productUrl + '&backgroundUrl=' + backgroundUrl + '&header=' +
        header + '&subheader=' + subheader + '&buyBtnColor=' + encodeURIComponent(buyBtnColor) + '&headerColor=' + encodeURIComponent(headerColor)
        + '&subheaderColor=' + encodeURIComponent(subheaderColor)
        + '&redirectBtnColor=' + encodeURIComponent(redirectBtnColor) + '&directionConfig=' + directionConfig;
        if(this.model.versionId===null || this.isNew || this.isCopy) { 
            url += '&redirectUrl=' + encodeURIComponent(redirectUrl || '');
        }
        window.open(url);
    }

    showSuccess(message: string): void {
        this.toastr.success(message);
    }

    showError(message: string): void {
        this.toastr.error(message);
    }

    close() {
        this.open = false;
        this.modalClose.emit(true);
    }

    toggleVersionDisable() {
        this.disableVersionTextbox = !this.disableVersionTextbox;
        if (this.disableVersionTextbox) {
            this.openVersionDetailModal = true;
        }
    }

    onVersionDetailModalClose(version: ProductVersionModel): void {
        console.log(version);
        this.openVersionDetailModal = false;
        if (version) {
            this.model.versionId = version.id;
            this.model.versionIdString = version.versionIdString;
        }
        setTimeout(() => {
            const ele = document.getElementById('versionId');
            if(ele) {
                ele.blur();
            }
        }, 1);
        this.disableVersionTextbox = false;
      }

      removeVersion() {
          this.model.versionIdString = null;
          this.model.versionId = null;
      }

}
